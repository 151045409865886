/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import { Suspense } from 'react';

import { CategorySortField } from 'Component/CategorySort/CategorySort.type';
import { SORT_ICON } from 'Component/Common/Common.config';
import Image from 'Component/Image';
import {
    CategoryFilterOverlay,
    CategoryPageComponent as SourceCategoryPageComponent,
    CategorySort,
    FilterIcon,
    GridIcon,
    ListIcon,
} from 'SourceRoute/CategoryPage/CategoryPage.component';
import { ReactElement } from 'Type/Common.type';
import { isCrawler, isSSR } from 'Util/Browser';

import './CategoryPage.override.style';

export {
    CategoryFilterOverlay,
    FilterIcon,
    GridIcon,
    ListIcon,
    CategorySort,
};

/** @namespace Pwa/Route/CategoryPage/Component */
export class CategoryPageComponent extends SourceCategoryPageComponent {
    renderMiscellaneous(): ReactElement {
        return (
            <aside block="CategoryPage" elem="Miscellaneous">
                { this.renderItemsCount() }
                <div
                  block="CategoryPage"
                  elem="MiscellaneousLayoutWrapper"
                >
                  <div
                    block="CategoryPage"
                    elem="LayoutWrapper"
                    mods={ { isPrerendered: isSSR() || isCrawler() } }
                  >
                      { /* { this.renderLayoutButtons() } */ }
                      { this.renderCategorySort() }
                  </div>
                  <div
                    block="CategoryPage"
                    elem="LayoutWrapper"
                    mods={ { isPrerendered: isSSR() || isCrawler() } }
                  >
                      { this.renderFilterButton() }
                  </div>
                </div>
            </aside>
        );
    }

    sortAttributes(attributes: any) {
        Object.keys(attributes).forEach((key) => {
            const attribute = attributes[key];

            attribute.attribute_values.sort((a: string, b: string) => {
                const labelA = attribute.attribute_options[a]?.label || '';
                const labelB = attribute.attribute_options[b]?.label || '';

                return labelA.localeCompare(labelB);
            });

            const sortedOptions: Record<string, any> = {};

            attribute.attribute_values.forEach((value: string) => {
                if (attribute.attribute_options[value]) {
                    sortedOptions[value] = attribute.attribute_options[value];
                }
            });

            attribute.attribute_options = sortedOptions;
        });

        return attributes;
    }

    renderFilterOverlay(): ReactElement {
        const {
            filters,
            selectedFilters,
            isMatchingInfoFilter,
            isSearchPage,
            mobileBackdrop,
        } = this.props;

        const { category: { is_anchor } } = this.props;

        if (!this.displayProducts()) {
            return null;
        }

        const filterWithoutPrice = this.sortAttributes(filters);
        delete filterWithoutPrice.price;

        return (
          <Suspense fallback={ this.renderFilterPlaceholder() || null }>
              <CategoryFilterOverlay
                availableFilters={ filterWithoutPrice }
                customFiltersValues={ selectedFilters }
                isMatchingInfoFilter={ isMatchingInfoFilter }
                isCategoryAnchor={ !!is_anchor }
                isSearchPage={ isSearchPage }
                renderPlaceholder={ this.renderPlaceholder }
                categoryPageRef={ this.categoryPageRef }
                mobileBackdrop={ mobileBackdrop }
              />
          </Suspense>
        );
    }

    renderCategorySort(): ReactElement {
        const {
            sortFields,
            selectedSort,
            onSortChange,
            isMatchingInfoFilter,
            isCurrentCategoryLoaded,
            isMobile,
        } = this.props;

        const { options = [] } = sortFields;
        const updatedSortFields: CategorySortField[] = options.map(({ value: id, label }) => ({ id, label }));
        const { sortDirection, sortKey } = selectedSort;

        if (isMobile && !isMatchingInfoFilter) {
            return this.renderFilterButtonPlaceholder();
        }

        return (
          <Suspense fallback={ <div block="CategoryPage" elem="CategorySortPlaceholder" /> }>
              <div block="CategoryPage" elem="SortBy">
                <Image src={ SORT_ICON } isPlain />
                { __('Sort by') }
              </div>
              <CategorySort
                isCurrentCategoryLoaded={ isCurrentCategoryLoaded }
                isMatchingInfoFilter={ isMatchingInfoFilter }
                onSortChange={ onSortChange }
                sortFields={ updatedSortFields }
                sortKey={ sortKey }
                sortDirection={ sortDirection }
              />
          </Suspense>
        );
    }
}

export default CategoryPageComponent;
