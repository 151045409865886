import TextPlaceholder from 'Component/TextPlaceholder';
import {
    CategoryItemsCountComponent as SourceCategoryItemsCountComponent,
} from 'SourceComponent/CategoryItemsCount/CategoryItemsCount.component';
import { ReactElement } from 'Type/Common.type';

import { CategoryItemsCountComponentProps } from './CategoryItemsCount.type';

import './CategoryItemsCount.override.style';

/** @namespace Pwa/Component/CategoryItemsCount/Component */
export class CategoryItemsCountComponent extends SourceCategoryItemsCountComponent {
    props!: CategoryItemsCountComponentProps;

    render(): ReactElement {
        const {
            totalItems,
            isMatchingListFilter,
            // totalPages,
            // pages,
            currentArgs: { currentPage = 1, pageSize = 1 },
        } = this.props;

        const firstRange = (currentPage - 1) * pageSize + 1;
        const lastRange = Math.min(currentPage * pageSize, totalItems);

        return (
            <p block="CategoryPage" elem="ItemsCount">
                <TextPlaceholder
                  content={ (!isMatchingListFilter
                      ? __('Products are loading...')
                  //   : __('%s items found', totalItems)
                      : __('Products %s-%s of %s', firstRange, lastRange, totalItems)
                  ) }
                />
            </p>
        );
    }
}

export default CategoryItemsCountComponent;
