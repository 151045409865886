import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
} from 'SourceComponent/CategoryItemsCount/CategoryItemsCount.container';
import { RootState } from 'Util/Store/Store.type';

import CategoryItemsCount from './CategoryItemsCount.component';
/** @namespace Pwa/Component/CategoryItemsCount/Container/mapStateToProps */
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    totalPages: state.ProductListReducer.totalPages,
    pages: state.ProductListReducer.pages,
    currentArgs: state.ProductListReducer.currentArgs,
});

/** @namespace Pwa/Component/CategoryItemsCount/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({
    ...sourceMapDispatchToProps(),
    // TODO extend mapDispatchToProps
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryItemsCount);
